<template>
	<section class="gardenManagementPage">
		<g-breadcrumb bread="订单管理" />
		<SearchForm>
			<el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px">
				<el-form-item prop="realName">
					<el-input v-model.trim="searchForm.realName" placeholder="请输入客户姓名" />
				</el-form-item>
				<el-form-item prop="mobilePhone">
					<el-input v-model.trim="searchForm.mobilePhone" placeholder="请输入客户手机号码" />
				</el-form-item>
				<el-form-item label="" prop="merchantId">
					<el-select v-model="searchForm.merchantId" placeholder="请选择所属供应商" @change="changeDoctor" filterable clearable>
						<el-option v-for="item in options2" :key="item.merchantId" :label="item.merchantName"
							:value="item.merchantId" />
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="fetchData">搜 索</el-button>
				</el-form-item>
			</el-form>
		</SearchForm>
		<TableContainer title="订单列表">
			<!-- <template slot="button">
				<el-button type="success" @click="addGarden">添加订单</el-button>
			</template> -->
			<el-table :data="tableData" stripe style="width: 100%" :header-cell-style="tabHeader" :cell-style="{ textAlign: 'center' }">
				<el-table-column prop="id" label="id" min-width="80" />
				<el-table-column prop="realName" label="客户名" min-width="100" />
				<el-table-column prop="mobilePhone" label="电话号码" min-width="100" />
				<el-table-column prop="birthday" label="生日" min-width="100" />
				<!-- <el-table-column prop="principalName" label="下单数" min-width="100" />
				<el-table-column prop="phone" label="支付数" min-width="100" />
				<el-table-column prop="remark" label="备注" min-width="100" show-overflow-tooltip /> -->
				<el-table-column width="120" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="editGarden(scope.row)">查看用户订单表</el-button>
						<!-- <el-button type="text" class="textOrange" @click="deleteGarden(scope.row)">删除</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
				:size.sync="searchForm.size" @pagination="fetchData" />
		</TableContainer>
		<GDialog :dialog.sync="gardenDialog" @btnSure="submitThemeForm()"
			@btnClose="resetGardenForm()">
			<SearchForm>
				<el-form ref="ref_searchForm" :inline="true" :model="searchForm1" label-width="80px" @submit.native.prevent>
					<el-form-item label="" prop="realName">
						<el-input v-model="searchForm1.realName" placeholder="请输入用户名称" />
					</el-form-item>
					<el-form-item label="" prop="mobilePhone">
						<el-input v-model="searchForm1.mobilePhone" placeholder="请输入用户手机号码" />
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="fetchData1">搜 索</el-button>
					</el-form-item>
				</el-form>
			</SearchForm>
			<el-table :data="tableData1" stripe style="width: 100%">
				<el-table-column prop="mfDockorId" label="客户id" min-width="100" />
				<el-table-column prop="mchId" label="所属商户" min-width="100" />
				<!-- <el-table-column prop="tradeNo" label="订单编号" min-width="100" /> -->
				<el-table-column prop="customerName" label="客户昵称" min-width="100" />
				<el-table-column prop="customerId" label="买家ID" min-width="100" />
				<el-table-column prop="customerNo" label="客户编码" min-width="100" />
				<el-table-column prop="receiverAddress" label="地址" min-width="100" />
				<el-table-column prop="receiverMobile" label="手机" min-width="100" />
				<el-table-column prop="paid" label="已付金额" min-width="100" />
				<el-table-column prop="created" label="订单日期" min-width="120" />
			</el-table>
		</GDialog>
		
		
		
	</section>

</template>

<script>
	import {
		mapState
	} from 'vuex'
	import {
		postFindMfDockor,
		findMfDockorUser,
		updateOrg,
		selectAllTrade,
		delOrg
	} from '@api/gardenManagement/api_gardenManagement'
	import {
		findMfMch,
	} from '@api/userMangement/api_userMangement'
	export default {
		data() {
			return {
				currentRow: {},
				gardenDialog: {
					title: '标题',
					visible: false
				},
				gardenDialog1: {
					title: '标题',
					visible: false
				},
				tableData: [],
				tableData1: [],
				tableData2: [],
				total: 0,
				options2: [],
				searchForm: {
					merchantId: '',
					keyWord: "",
					mobilePhone: "",
					realName: "",
					page: 1,
					size: 20,
					startNum: 1
				},
				searchForm1: {
					// merchantId: 1,
					// keyWord: "",
					mobilePhone: "",
					realName: "",
					page: 1,
					size: 20,
					startNum: 1
				},
				
				gardenForm: {
					mobilePhone: '',
					realName: '',
					principalName: '',
					phone: '',
					remark: ''
				},
			}
		},
		computed: {
			...mapState('commonModule', ['orgAreaTree'])
		},
		created() {
			// this.getOptions()
			this.fetchData()
			this.findMfMch();
		},

		methods: {
			// getOptions() {
			// 	// 查询组织机构树-到片区；2到片区，3到园所
			// 	this.$store.dispatch('commonModule/getOrgTree', 2)
			// },
			tabHeader() {
				return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
			},
			fetchData() {
				this.searchForm.type = 3
				postFindMfDockor(this.searchForm).then(res => {
				  this.tableData = res.records
				  this.total = res.total
				})
			},
			fetchData1() {
				this.searchForm.type = 3
				findMfDockorUser(this.searchForm1).then(res => {
				  this.tableData1 = res.records
				  this.total = res.total
				})
			},
			changeDoctor(val){
				console.log(val)
				this.searchForm.merchantId = val 
			},
			// addGarden() {
			// 	this.gardenDialog.visible = true
			// 	this.gardenDialog.title = '添加订单'
			// },
			editGarden(row) {
				console.log(row,1111)
				this.gardenDialog.visible = true
				this.gardenDialog.title = "用户信息"
				let params = {
					parentId: row.userId,
					page: 1,
					size: 10
					
				}
				selectAllTrade(params).then(res => {
				  this.tableData1 = res.records
				  this.total = res.total
				})
			},
			findMfMch(){
				let params = {};
				findMfMch(params).then((res) =>{
					this.options2 = res
				})
			},
			editGarden1(row){
				this.gardenDialog1.visible = true
				this.gardenDialog1.title = ""
			},
			deleteGarden(row) {
				this.$confirm('是否确认删除?', '提示', {
					type: 'warning',
					closeOnClickModal: false
				}).then(() => {
					this.currentRow = row
					delOrg({
						orgId: row.id
					}).then(res => {
						this.$message.success('删除成功！')
						if (this.total % this.searchForm.size === 1) {
							this.searchForm.page = this.searchForm.page - 1 === 0 ? 1 : this.searchForm
								.page - 1
						}
						this.fetchData()
					})
				})
			},

			// submitForm(formName) {
			// 	this.$refs[formName].validate(valid => {
			// 		if (!valid) {
			// 			return false
			// 		}
			// 		const params = {
			// 			...this.gardenForm
			// 		}
			// 		let _api
			// 		if (this.gardenDialog.title === '添加园所') {
			// 			_api = addOrg
			// 		} else {
			// 			_api = updateOrg
			// 			params.orgId = this.currentRow.id
			// 		}
			// 		_api(params).then(res => {
			// 			this.$message.success(this.gardenDialog.title + '成功！')
			// 			this.resetGardenForm('ref_gardenForm')
			// 			this.fetchData()
			// 		})
			// 	})
			// },
			submitThemeForm(){
				this.gardenDialog.visible = false
			},
			resetGardenForm(formName) {
				// this.$refs[formName].resetFields()
				this.gardenDialog.visible = false
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.reviewed {
		color: #38b9be;
	}
</style>
